import { createPopper } from "@popperjs/core";

export const openDropdown = (event, dropdownID) => {
  let element = event.target;
  while (element.nodeName !== "BUTTON") {
    element = element.parentNode;
  }
  var popper = createPopper(element, document.getElementById(dropdownID), {
    placement: "bottom-start",
  });
  document.getElementById(dropdownID).classList.toggle("hidden");
  document.getElementById(dropdownID).classList.toggle("block");
};

export const getNameInitials = (nameString: string): string => {
  nameString = nameString?.trim() || "";
  let rv = nameString.charAt(0);
  const splittedName = nameString.replace(/\s+/g, " ").split(" ") || [];

  if (splittedName.length > 1) {
    rv += splittedName[1].charAt(0);
  }

  return rv.toUpperCase();
};

export const language = (language) => {
  switch (language?.trim().toLowerCase()) {
    case "en":
      return "English";
    case "en_us":
      return "English (US)";
    case "en_uk":
      return "English (UK)";
  }
};

export const colorCode = (status) => {
  switch (status?.trim().toLowerCase()) {
    case "active":
    case "resume":
    case "resumed":
    case "verified":
      return "bg-green-5 text-primary-green";
    case "approved":
      return "bg-green-5 text-primary-green";
    case "enabled":
    case "running":
      return "bg-green-5 text-primary-green";
    case "completed":
      return "bg-green-5 text-primary-green";
    case "delayed":
    case "disabled":
    case "suspended":
      return "bg-gray-100 text-gray-700";
    case "broken":
    case "canceled":
    case "cancelled":
    case "deactivated":
    case "rejected":
      return "bg-error-50 text-error-700";
    case "draft":
    case "pending":
    case "pending approval":
      return "bg-warning-50 text-error-700";
    case "pause":
    case "paused":
    case "incomplete":
      return "bg-warning-50 text-warning-600";
    case "not started":
      return "bg-light-purple text-dark-purple";
    case "scheduled":
      return "bg-light-blue text-dark-blue";
    default:
      return "bg-gray-100 text-gray-700";
  }
};

const textStyle = {
  fontFamily: '"BR Firma", sans-serif',
};

export const customChartTheme = {
  textStyle,
  title: {
    textStyle,
  },
  tooltip: {
    textStyle,
  },
};

export const formatNumbers = (num: any) => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatNumberSuffix = (num) => {
  if (num == null || isNaN(num)) return "0"; // Handle null, undefined, and non-numeric values
  if (num < 0) return "-" + formatNumberSuffix(-num); // Handle negative numbers

  if (num >= 1_000_000)
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  if (num >= 1_000) return (num / 1_000).toFixed(1).replace(/\.0$/, "") + "k";

  return num.toString();
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  // const k = 1024;
  // const sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  const k = 1000;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const dm = decimals < 0 ? 0 : decimals;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return "";
  str = str.trim().toLowerCase();
  const value = str.charAt(0).toUpperCase() + str.slice(1);
  return value;
};

export const downloadZip = (data, name) => {
  const blob = new Blob([data], { type: "application/zip" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", name);
  a.click();
};

export const downloadCSV = (csv, name) => {
  const blob = new Blob([csv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", name);
  a.click();
};

export const downloadStringAsCSV = (data: string, fileName = "") => {
  const lines = data.trim().split("\n");

  // Modify the header row: replace underscores with spaces and convert to title case
  const headerRow = lines[0]
    .split(",")
    .map((columnName) =>
      columnName
        .replace(/_/g, " ")
        .replace(
          /\w\S*/g,
          (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        )
    )
    .join(",");

  // Extract the data rows after the header
  const dataRows = lines.slice(1).join("\n");

  // Combine the header and data rows into the final CSV content
  const csvContent = `${headerRow}\n${dataRows}`;

  downloadCSV(csvContent, `${fileName && fileName + " - "}BillingReport.csv`);
};
